<template>
  <div :class="brandClasses.wrapper" data-test-id="form-sign-in">
    <vf-notification
      v-if="notification.message"
      class="mb-4"
      :dismissible="false"
      :type="notification.type"
      @close="notification.message = ''"
    >
      <base-interpolator :content="notification.message">
        <template #reset="{ args: [message] }">
          <base-link class="underlined" target="_blank" to="/forgot-password">
            {{ message }}
          </base-link>
        </template>
        <template #contact="{ args: [message] }">
          <base-link class="underlined" target="_blank" to="/help/contact-us">
            {{ message }}
          </base-link>
        </template>
      </base-interpolator>
    </vf-notification>
    <template v-if="!compact">
      <h2 v-if="!insideDialog" :class="brandClasses.heading">
        {{ $t.signInTitle }}
      </h2>
      <p :class="brandClasses.helpText">
        {{ $t.requiredField }}
      </p>
    </template>
    <base-form :class="brandClasses.form" :form validate-on="change" @submit="submit">
      <vf-form-field
        v-slot="{ attrs }"
        :hint="disableEmail ? undefined : $t.emailFormatHint"
        name="email"
        :rule="[validateRequired($t.email), validateEmail(), validateMaxLength(100)]"
      >
        <vf-input
          v-model="form.email"
          v-bind="attrs"
          :disabled="disableEmail"
          required
          type="email"
          @change="handleEmailChange"
        >
          {{ $t.email }}
        </vf-input>
      </vf-form-field>
      <vf-form-field v-slot="{ attrs }" name="password" :rule="validateRequired($t.password)">
        <input-password v-model="form.password" class="mt-4" v-bind="attrs" required>
          {{ $t.password }}
        </input-password>
      </vf-form-field>
      <slot name="forgot-password">
        <div :class="brandClasses.forgotPassword">
          <vf-link
            data-test-id="form-sign-in-link-forgot-password"
            :to="insideDialog ? undefined : '/forgot-password'"
            @click="handleForgotPassword"
          >
            {{ $t.forgotPassword }}
          </vf-link>
        </div>
      </slot>
      <vf-button :class="brandClasses.submitButton" :loading :size="buttonSize" type="submit">
        {{ $t.signInSubmit }}
      </vf-button>
      <div v-if="$feature.allowNewSignUp && showSignUp" :class="brandClasses.signUp">
        <base-interpolator :content="$t.notAMember">
          <template #createAccount>
            <vf-link class="ml-1" @click="openDialogSignUp">
              {{ $t.createAccount }}
            </vf-link>
          </template>
        </base-interpolator>
      </div>
    </base-form>
    <vf-button
      v-if="$feature.showSeparateSignupCtaInSigninModal && insideDialog"
      class="mt-4 <md:full"
      type="submit"
      variant="tertiary"
      @click="openDialogSignUp"
    >
      {{ $t.joinLoyaltyCta }}
    </vf-button>
  </div>
</template>

<script lang="ts" setup>
import type { BaseNotification } from '#types/notification'
import type { FormLocation } from '#types/gtm'

const {
  compact,
  disableEmail,
  email = '',
  formLocation = 'modal:single:none',
  insideDialog,
  preventDialog,
  isSignInToBuy,
  showSignUp
} = defineProps<{
  compact?: boolean
  disableEmail?: boolean
  email?: string
  formLocation?: FormLocation
  insideDialog?: boolean
  preventDialog?: boolean
  isSignInToBuy?: boolean
  showSignUp?: boolean
}>()

const emit = defineEmits<{
  forgotPassword: []
  signUp: []
  submit: []
  close: []
}>()

const { brandClasses, buttonSize } = useAppConfig().components.form.signIn
const { login } = useAuthStore()
const { getSections } = useCms()
const { DialogForgotPassword, DialogSignUp } = useDialogsStore()
const { validateEmail, validateMaxLength, validateRequired } = useLocalizedValidators()
const { $gtm, $t } = useNuxtApp()
const user = useUserStore()
const recaptcha = useRecaptcha()

const form = reactive({
  email,
  password: ''
})

const notification = reactive<BaseNotification>({
  message: '',
  type: ''
})

const loading = ref(false)

const handleForgotPassword = () => {
  if (insideDialog) {
    emit('forgotPassword')
    if (!preventDialog) DialogForgotPassword.open()
  }
}

const handleEmailChange = () => {
  $gtm.push('authForm.onEmail', 'Login', formLocation)
}

const submit = async () => {
  try {
    notification.message = '' // remove the error message, if any
    loading.value = true

    $gtm.push('authForm.onLoginSubmit', formLocation, false)

    const token = await recaptcha.execute('login')
    await login(form.email, form.password, token)

    notification.message = $t.youHaveSuccessfullyLoggedIn
    notification.type = 'success'

    await user.get()

    emit('submit')

    $gtm.push('user.onLoadUserData', await getUserEventData())
    $gtm.push('authForm.onLoginConfirmed', formLocation, 'Standard', false)
  }
  catch (err) {
    assertApiError(err)
    notification.message = err.message
    notification.type = 'error'
  }
  finally {
    loading.value = false
  }
}

const openDialogSignUp = async () => {
  emit('signUp')
  if (!preventDialog) {
    const eSpots = (await getSections.signUpModal()).data.value?.content.sectionsMap
    await DialogSignUp.keep().open(
      {
        formLocation,
        eSpot: eSpots?.['below-sign-up'],
        isSignInToBuy
      },
      { size: eSpots?.['below-sign-up'] ? 'md' : 'xs' }
    )
    emit('close')
  }
}

onMounted(() => {
  $gtm.push('authForm.onImpression', 'Login', formLocation)
  form.email.length > 0 && (document.querySelector('[type="password"]') as HTMLElement)?.focus()
})
</script>
